angular.module('import')

  .controller('Imports',
    ['Import', 'Place', 'Modal', '$scope', '$timeout', '$uibModal', '$stateParams', '$state', 'type', 'reservationImportType',
      function (Import, Place, Modal, $scope, $timeout, $uibModal, $stateParams, $state, type, reservationImportType) {
        var vm = this;

        vm.params = {
          type: type,
          page: 1,
          q: ''
        };

        vm.params.q = $stateParams.code || '';


        vm.currents = {};

        vm.page_change = function () {
          Import.all(vm.params)
            .then(function (imports) {
              vm.imports = imports;
            });
        };
        vm.page_change();

        $scope.$watch('vm.params.q', function (qnew, qold) {
          if (qnew === qold)
            return;

          if (qnew) {
            vm.page_change();
          } else {
            vm.params.page = 1;
            vm.page_change();
          }
        });

        vm.clean = function (place) {
          if (place.clean)
            return;

          var title = 'Cleaning - place <b>' + place.name + '</b>';
          var body = 'Set the place as cleaned';

          Modal.confirm(title, body).then(function () {
            Place.clean(place.id, true)
              .then(function () {
                place.clean = true;
                vm.success = true;

                $timeout(function () {
                  vm.success = false;
                }, 1000);
              });
          });
        };

        vm.comments = function (comments) {
          Modal.inform('Comments', comments);
        };

        vm.delete = function (ri) {
          var title = 'Delete import';
          var body = 'Confirm the permanent deletion of the import';

          Modal.confirm(title, body).then(function () {
            ri.remove()
              .then(function () {
                var total = vm.imports.total - 1;
                vm.imports = _.without(vm.imports, ri);
                vm.imports.total = total;
              });
          });
        };

        vm.metrics = function (reservation) {
          var modal_scope = $scope.$new(false);

          modal_scope.payments = reservation.payments;

          modal_scope.modal = $uibModal.open({
            templateUrl: require('../reservation/payments.modal.html'),
            scope: modal_scope,
            size: 'lg',
            windowClass: 'payments-modal'
          });
        };

        vm.detail = function (synchro_import) {
          const constant = Object.values(reservationImportType).find(({id}) => id === type);
          $state.go(constant.route, {import_id: synchro_import.id});
        }
      }])

  .controller('ImportNew',
    ['$scope', 'Import', 'Reservation', 'Place', '$stateParams', '$timeout', '$state', 'Modal', 'Maid',
      function ($scope, Import, Reservation, Place, $stateParams, $timeout, $state, Modal, Maid) {
        var vm = this;

        vm.reservation = {};
        vm.day_hours = [];

        vm.init = function () {
          Import.get($stateParams.import_id)
            .then((d) => {
              vm.reservation_set(d)
            });
          for (var i = 8; i <= 24; i++) {
            vm.day_hours.push(i + ':00');
          }
        };

        vm.reservation_set = function (d) {
          vm.reservation = d;
          const today = new Date();

          let from_date = ''
          let to_date = ''
          if (d.from_date)
            from_date = new Date(d.from_date);

          if (d.to_date)
            to_date = new Date(d.to_date);

          const diff = vm.reservation && vm.reservation.diff ? vm.reservation.diff.split(',') : []

          vm.reservation = Object.assign({}, vm.reservation, {
            guest: {
              firstname: d.guest_firstname,
              lastname: d.guest_lastname,
              email: d.guest_email,
              phone: d.guest_phone,
            },
            diff,
            currency: d.currency ? d.currency : 'CHF',
            date: d.date ? new Date(d.date) : null,
            from_date,
            from_time: dayjs(from_date).format('HH:mm'),
            to_date,
            to_time: dayjs(to_date).format('HH:mm'),
            maid: d.maid,
            maid_id: d.maid_id,
            maid_duration: d.maid_duration,
            maid_additional_informations: d.maid_additional_informations,
            maid_access_informations: d.maid_access_informations,
            email_in_b7: d.email_in_b7,
            email_out_a1: d.email_out_a1,
            comments: d.comments
          });
        };

        vm.places_search = function (value) {
          return Place.search(value)
            .then(function (places) {
              return places;
            });
        };

        vm.init()

        vm.save = function (form, reservation) {
          if (form.$invalid)
            return;

          vm.processing = true;

          const today = new Date();
          var days = dayjs(reservation.from_date).diff(today, 'day') + 1;

          var body = "Guest and Host will be notified by email."

          if (days < 7)
            body += "<br><br><b> As the reservation begin under 7 days, the Arrival Informations email will directly be send to the Guest </b>";

          body += "<br><br> The reservation will be created and available in the Future Check-in listing.";

          Modal
            .confirm('Confirm the reservation', body)
            .then(() => {
              var ftime = vm.reservation.from_time.split(':');
              reservation.from_date.setHours(ftime[0]);
              reservation.from_date.setMinutes(ftime[1]);

              var ttime = vm.reservation.to_time.split(':');
              reservation.to_date.setHours(ttime[0]);
              reservation.to_date.setMinutes(ttime[1]);
              reservation.place_id = reservation.place.id

              Import.confirm({
                reservation,
                meta: {listing_id: vm.reservation.listing_id, id: vm.reservation.id}
              }).then(() => {
                vm.processing = false;
                vm.success = "Reservation updated successfully";

                $timeout(function () {
                  $state.go('app.imports.new');
                }, 1000);
              })
            }).catch(() => {vm.processing = false;});
        };

        vm.show_reservation = function () {
          const windowSize = {
            width: 800,
            height: 800,
          };
          const windowLocation = {
            left: (window.screen.availLeft + window.screen.availWidth),
            top: (window.screen.availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
          };
          window.open(`https://www.airbnb.fr/messaging/qt_for_reservation/${vm.reservation.code}`, '_blank', 'width=' + windowSize.width + ', height=' + windowSize.height + ', left=' + windowLocation.left + ', top=' + windowLocation.top);
        };

        vm.place_changed = function (item) {
          Modal
            .confirm('Place changed', 'Since the place\'s changed, the import can be updated')
            .then(() => {
              Import.update_from_place({place_id: item.id, id: vm.reservation.id}).then((d) => {
                vm.reservation_set(d)
              })
            });
        };

        vm.maids_search = function (value) {
          return Maid.search(value)
            .then(function (places) {
              return places;
            });
        };
      }])
  .controller('ImportUpdated',
    ['$scope', 'Import', 'Reservation', 'Place', '$stateParams', '$timeout', '$state', 'Modal', 'Maid',
      function ($scope, Import, Reservation, Place, $stateParams, $timeout, $state, Modal, Maid) {
        var vm = this;

        vm.reservation = {};
        vm.reservation_current = {};
        vm.day_hours = [];

        vm.init = function () {
          Import.get($stateParams.import_id)
            .then((data) => {
              Reservation.search_by_code(data.code)
                .then(d => {

                  vm.reservation_current = d
                  if (d.from_date)
                    vm.reservation_current.from_date = dayjs(d.from_date).format('DD/MM/YYYY')

                  if (d.to_date)
                    vm.reservation_current.to_date = dayjs(d.to_date).format('DD/MM/YYYY')

                })
              return data
            })
            .then((d) => {
              vm.reservation_set(d)
            });


          for (var i = 8; i <= 24; i++) {
            vm.day_hours.push(i + ':00');
          }
        }

        vm.reservation_set = function (d) {
          vm.reservation = d;
          const today = new Date();
          const from_date = d.from_date ? new Date(d.from_date) : today;
          const to_date = d.to_date ? new Date(d.to_date) : new Date(new Date().setDate(today.getDate() + 1));
          const diff = vm.reservation && vm.reservation.diff ? vm.reservation.diff.split(',') : []
          vm.reservation = Object.assign({}, vm.reservation, {
            guest: {
              firstname: d.guest_firstname,
              lastname: d.guest_lastname,
              email: d.guest_email,
              phone: d.guest_phone,
            },
            diff,
            currency: d.currency ? d.currency : 'CHF',
            date: d.date ? new Date(d.date) : null,
            from_date,
            from_time: dayjs(from_date).format('HH:mm'),
            to_date,
            to_time: dayjs(to_date).format('HH:mm'),
            maid: d.maid,
            maid_id: d.maid_id,
            maid_duration: d.maid_duration,
            maid_additional_informations: d.maid_additional_informations,
            maid_access_informations: d.maid_access_informations,
            email_in_b7: d.email_in_b7,
            email_out_a1: d.email_out_a1,
            comments: d.comments
          });
        };

        vm.places_search = function (value) {
          return Place.search(value)
            .then(function (places) {
              return places;
            });
        };

        vm.updated_field = function (field) {
          if (vm.reservation && vm.reservation.diff) {
            return vm.reservation.diff.includes(field);
          }
          return false;
        };

        vm.init()

        vm.save = function (form, reservation) {
          if (form.$invalid)
            return;

          vm.processing = true;

          const today = new Date();
          const days = dayjs(reservation.from_date).diff(today, 'day') + 1;

          let body = "Guest and Host will be notified by email."

          if (days < 7)
            body += "<br><br><b> As the reservation begin under 7 days, Guest & Host will be notified again. </b>";

          body += "<br><br> The reservation will be created and available in the Future Check-in listing.";

          Modal
            .confirm('Confirm the reservation', body)
            .then(() => {
              var ftime = vm.reservation.from_time.split(':');
              reservation.from_date.setHours(ftime[0]);
              reservation.from_date.setMinutes(ftime[1]);

              var ttime = vm.reservation.to_time.split(':');
              reservation.to_date.setHours(ttime[0]);
              reservation.to_date.setMinutes(ttime[1]);
              reservation.place_id = reservation.place.id

              Import.confirm({
                reservation,
                meta: {listing_id: vm.reservation.listing_id, id: vm.reservation.id}
              }).then(() => {
                vm.processing = false;
                vm.success = "Reservation updated successfully";

                $timeout(function () {
                  $state.go('app.imports.updated');
                }, 1000);
              })
            }).catch(() => {vm.processing = false;});
        };

        vm.show_reservation = function () {
          const windowSize = {
            width: 800,
            height: 800,
          };
          const windowLocation = {
            left: (window.screen.availLeft + window.screen.availWidth),
            top: (window.screen.availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
          };
          window.open(`https://www.airbnb.fr/messaging/qt_for_reservation/${vm.reservation.code}`, '_blank', 'width=' + windowSize.width + ', height=' + windowSize.height + ', left=' + windowLocation.left + ', top=' + windowLocation.top);
        };

        vm.place_changed = function (item) {
          Modal
            .confirm('Place changed', 'Since the place\'s changed, the import can be updated')
            .then(() => {
              Import.update_from_place({place_id: item.id, id: vm.reservation.id}).then((d) => {
                vm.reservation_set(d)
              })
            });
        };

        vm.maids_search = function (value) {
          return Maid.search(value)
            .then(function (places) {
              return places;
            });
        };
      }])
  .controller('ImportCanceled',
    ['$scope', 'Import', 'Reservation', 'Place', '$stateParams', '$timeout', '$state', 'Modal', '$uibModal',
      function ($scope, Import, Reservation, Place, $stateParams, $timeout, $state, Modal, $uibModal) {
        var vm = this;

        vm.reservation = {};
        vm.reservation_current = {};
        vm.day_hours = [];

        vm.init = function () {
          Import.get($stateParams.import_id)
            .then((data) => {
              Reservation.search_by_code(data.code)
                .then(d => {
                  vm.reservation_current = d
                  if (d.from_date)
                    vm.reservation_current.from_date = dayjs(d.from_date).format('DD/MM/YYYY')

                  if (d.to_date)
                    vm.reservation_current.to_date = dayjs(d.to_date).format('DD/MM/YYYY')

                })
              return data
            })
            .then((d) => {
              vm.reservation_set(d)
            });


          for (var i = 8; i <= 24; i++) {
            vm.day_hours.push(i + ':00');
          }
        }

        vm.reservation_set = function (d) {
          vm.reservation = d;
          const today = new Date();
          const from_date = d.from_date ? new Date(d.from_date) : today;
          const to_date = d.to_date ? new Date(d.to_date) : new Date(new Date().setDate(today.getDate() + 1));
          const diff = vm.reservation && vm.reservation.diff ? vm.reservation.diff.split(',') : []
          vm.reservation = Object.assign({}, vm.reservation, {
            guest: {
              firstname: d.guest_firstname,
              lastname: d.guest_lastname,
              email: d.guest_email,
              phone: d.guest_phone,
            },
            diff,
            currency: d.currency ? d.currency : 'CHF',
            date: d.date ? new Date(d.date) : null,
            from_date,
            from_time: dayjs(from_date).format('HH:mm'),
            to_date,
            to_time: dayjs(to_date).format('HH:mm'),
          });
        }

        vm.places_search = function (value) {
          return Place.search(value)
            .then(function (places) {
              return places;
            });
        };

        vm.updated_field = function (field) {
          if (vm.reservation && vm.reservation.diff) {
            return vm.reservation.diff.includes(field);
          }
          return false;
        };

        vm.init()

        vm.save = function (form, reservation) {
          if (form.$invalid)
            return;

          vm.processing = true;

          let body = "Do you really want to cancel the reservation"

          Modal
            .confirm('Confirm the reservation', body)
            .then(() => {
              var ftime = vm.reservation.from_time.split(':');
              reservation.from_date.setHours(ftime[0]);
              reservation.from_date.setMinutes(ftime[1]);

              var ttime = vm.reservation.to_time.split(':');
              reservation.to_date.setHours(ttime[0]);
              reservation.to_date.setMinutes(ttime[1]);
              reservation.place_id = reservation.place.id

              var modal_scope = $scope.$new(false);

              modal_scope.submitted = false;
              modal_scope.cancel = function (initiated_by) {
                if (modal_scope.submitted)
                  return;

                modal_scope.submitted = true
                vm.processing = false;

                Import.confirm({
                  reservation,
                  meta: {listing_id: vm.reservation.listing_id, id: vm.reservation.id, initiated_by: initiated_by}
                }).then(() => {
                  vm.success = "Reservation updated successfully";

                  $timeout(function () {
                    $state.go('app.imports.canceled');
                  }, 1000);
                })
              }

              modal_scope.modal = $uibModal.open({
                templateUrl: require('../reservation/cancel.modal.html'),
                scope: modal_scope,
                backdrop: 'static'
              });
            }).catch(() => {vm.processing = false;});
        };

        vm.show_reservation = function () {
          const windowSize = {
            width: 800,
            height: 800,
          };
          const windowLocation = {
            left: (window.screen.availLeft + window.screen.availWidth),
            top: (window.screen.availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
          };
          window.open(`https://www.airbnb.fr/messaging/qt_for_reservation/${vm.reservation.code}`, '_blank', 'width=' + windowSize.width + ', height=' + windowSize.height + ', left=' + windowLocation.left + ', top=' + windowLocation.top);
        };

        vm.place_changed = function (item) {
          Modal
            .confirm('Place changed', 'Since the place\'s changed, the import can be updated')
            .then(() => {
              Import.update_from_place({place_id: item.id, id: vm.reservation.id}).then((d) => {
                vm.reservation_set(d)
              })
            });
        }

        vm.maids_search = function (value) {
          return Maid.search(value)
            .then(function (places) {
              return places;
            });
        };
      }])
  .constant('reservationImportType', {
    'NEW': {'id': 0, 'label': 'new', 'class': 'default', 'route': 'app.imports.new.detail'},
    'UPDATED': {'id': 1, 'label': 'updated', 'class': 'info', 'route': 'app.imports.updated.detail'},
    'CANCELED': {'id': 2, 'label': 'canceled', 'class': 'info', 'route': 'app.imports.canceled.detail'},
  })
