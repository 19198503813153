var app = angular.module('core-controller', [])

.controller('Main',
    ['$scope', '$uibModal', '$stateParams', 'account', 'Notification', '$state', 'Reservation', 'Modal', '$timeout', 'reservationStatus', 'Report', 'Message', '$rootScope',
    function Main($scope, $uibModal, $stateParams, account, Notification, $state, Reservation, Modal, $timeout, reservationStatus, Report, Message, $rootScope) {
    var vm = this;

    vm.account = account;
    vm.state = $state;
    vm.todo = 0;
    vm.unverified = 0;
    vm.nb_reports = 0;
    vm.threads=[]

    var notification_loop;
    function notification_refresh() {
        Promise.all([
            Notification.todo(),
            Reservation.unverified(),
            Report.pending(),
            Message.all()
        ]).then(function (responses) {
            vm.todo = responses[0];
            vm.unverified = responses[1];
            vm.pending_reports = responses[2].count
            vm.threads = responses[3]
            vm.check_unread_messages();
            //notification_loop = $timeout(notification_refresh, 5000);
        });
    };

    if (account.admin)
         notification_refresh();

    vm.signout = function () {
        if (notification_loop)
            $timeout.cancel(notification_loop);

        vm.account.signout();
        localStorage.removeItem('token');
        $state.go('signin');
    };

    vm.email_confirm = function () {

        account.email_confirm()
            .then(function () {
                var title = 'Email confirm';
                var content = 'An email has been sent to your email address. ' +
                              'Please follow the instructions to confirm your email.';

                Modal.inform(title, content);
            });
    };

    vm.reservations_search = function(e) {
      e.stopPropagation();
      Modal.search_reservation()
        .then((code) => Reservation.search_by_code(code))
        .then((reservation) => {
          Object.keys(reservationStatus).forEach((k) => {
            if(reservationStatus[k].id === reservation.status && reservationStatus[k].route){
              $state.go(reservationStatus[k].route, { code: reservation.code })
              return;
            }
          });
          console.log('not found');
        })
    }

    vm.unread_messages = 0;

    vm.check_unread_messages = function () {
        let unread_messages = 0
        vm.threads.forEach((thread) => {
            if(thread.status === 0) {
                unread_messages ++
            }
        })

        vm.unread_messages = unread_messages;
    }

    $rootScope.$on('conversationAdded', function(e){
        vm.unread_messages += 1
    });

    $rootScope.$on('conversationRemoved', function(e){
        if(vm.unread_messages === 0){
            return
        }
        vm.unread_messages -= 1
    })

}]);
