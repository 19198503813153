var app = angular.module('calendar', [])

.controller('Calendar',
    ['$stateParams', '$scope', '$filter', 'Place', 'Modal', 'account', 'Reservation', '$state', '$location',
    function Calendar ($stateParams, $scope, $filter, Place, Modal, account, Reservation, $state, $location) {
    var vm = this;
    vm.account = account;
    var reservations = {};

    vm.params = {
        with_overlapping: $stateParams.with_overlapping !== undefined
                        ? $stateParams.with_overlapping
                        : true,
        with_vacant:      $stateParams.with_vacant !== undefined
                        ? $stateParams.with_vacant
                        : false,
        with_checkins:    $stateParams.with_checkins !== undefined
                        ? $stateParams.with_checkins
                        : true,
        with_checkouts:   $stateParams.with_checkouts !== undefined
                        ? $stateParams.with_checkouts
                        : true,
        selection:        $stateParams.selection !== undefined
                        ? $stateParams.selection
                        : false,
        from:             $stateParams.from !== undefined
                        ? $stateParams.from
                        : $filter('date')(new Date(), 'yyyy-MM-dd'),
        places:           $stateParams.places !== undefined
                        ? typeof $stateParams.places == 'string'
                            ? [$stateParams.places]
                            : $stateParams.places
                        : [],
    };
    vm.date_input = new Date(vm.params.from);
    vm.selection = vm.params.places;

    vm.DAY_IS = {
        VACANT: 0,
        CHECKIN: 1,
        CHECKOUT: 2,
        BOTH: 3,
        OCCUPIED: 4,
    }

    vm.hovered_id;

    vm.toggle = function (id) {
        var id = String(id);
        var index = vm.selection.indexOf(id);

        if (index > -1)
            vm.selection.splice(index, 1);
        else
            vm.selection.push(id);

        $state.go('.', {places: vm.selection}, {notify : false});

        if (vm.params.selection)
            vm.page_change();
    };

    vm.update_url = function(param_name) {
        // Declare and then set the value to prevent javascript from
        // interpreting param_name as the name of the query parameter
        // instead of resolving the variable
        var query_parameters = {};

        query_parameters[param_name] = vm.params[param_name];

        $state.go('.', query_parameters, {notify : false});

        vm.page_change();
    }

    vm.selected = function (id) {
        return vm.selection && vm.selection.indexOf(String(id)) > -1;
    };

    function to_simple_date_string(date_object) {
        var year = date_object.getFullYear();
        var month = date_object.getMonth() + 1;
        var day = date_object.getDate();
        // Did you know?
        // In javascript, new Date("2017-10-2") != new Date("2017-10-02"). Note the leading zero on day.
        return year + "-" + (month > 9 ? month : '0' + month)
                    + "-" + (day   > 9 ? day   : '0' + day);
    }

    function check_week () {
        for (var i = 0; i < vm.places.length; i++) {
            vm.places[i].week = [];
            for (var day = 0; day < 7; day++) {
                var current_date = to_simple_date_string(vm.week_date(day));
                var state = vm.DAY_IS.VACANT;
                var ids = [];
                // Loop through reservations of the current place in the visible week.
                // Can have up to 3 values (7 days in a week and reservation takes at least 2 days)
                if (vm.places[i].occupancy)
                    for (var rsrv of vm.places[i].occupancy) {
                        if (rsrv.from_date < current_date
                                          && current_date < rsrv.to_date) {
                            state = vm.DAY_IS.OCCUPIED;
                            ids[0] = rsrv.id;
                            break; // if a day is surrounded by a reservation, it cannot be checkin or checkout.
                        }
                        else if (current_date == rsrv.from_date) {
                            state += vm.DAY_IS.CHECKIN;
                            ids[1] = rsrv.id;
                        }
                        else if (current_date == rsrv.to_date) {
                            state += vm.DAY_IS.CHECKOUT;
                            ids[0] = rsrv.id;
                        }
                    }
                // If not both checkin and checkout, move checkin id in first position
                if (!ids[0])
                ids[0] = ids[1]
                // If a day has been checkin of a reservation and checkout of another,
                // the value will conveniently correspond to vm.DAY_IS.BOTH
                vm.places[i].week[day] = {state : state, reservations_id: ids};
            }
        }
    }

    function parse_bool_params () {
        for (var param_name in vm.params) {
            if (vm.params.hasOwnProperty(param_name)) {
                if (vm.params[param_name] == 'true' || vm.params[param_name] == 'false') {
                    vm.params[param_name] = JSON.parse(vm.params[param_name]);
                }
            }
        }
    }

    vm.page_change = function() {

        Place.calendar(vm.params)
        .then(function(places) {
            vm.places = places;
            check_week();
            parse_bool_params();
        });
    };
    vm.page_change();

    vm.week_date = function (index) {
        var d = new Date(vm.params.from);
        d.setDate(d.getDate() + index);
        return d;
    };

    vm.day_set = function (direction) {
        if (direction) {
            vm.date_input = new Date(vm.params.from);
            vm.date_input.setDate(vm.date_input.getDate() + direction);
        }
        vm.params.from = $filter('date')(vm.date_input, 'yyyy-MM-dd');
        vm.update_url('from');
    };

    vm.reservation = function (place, day, index) {
        function display (reservation, address) {
            var title = "Reservation";
            var body = '<p><b> Code </b> : '          + reservation.code                                            + '</p>' +
                       '<p><b> Address </b> : '       + address.street+', '+
                                                        address.postal_code+' '+address.city+' - '+
                                                        address.country                                             + '</p>' +
                       '<p><b> From </b> : '          + $filter('date')(reservation.from_date, 'short')             + '</p>' +
                       '<p><b> To </b> : '            + $filter('date')(reservation.to_date, 'short')               + '</p>' +
                       '<p><b> Guest name </b> : '    + reservation.guest.firstname+' '+reservation.guest.lastname  + '</p>' +
                       '<p><b> Guest email </b> : '   + reservation.guest.email                                     + '</p>' +
                       '<p><b> Guest phone </b> : '   + reservation.guest.phone                                     + '</p>' +
                       '<p><b> Guests </b> : '        + reservation.guest_count                                     + '</p>' +
                       '<p><b> Beds </b> : '          + reservation.bed_count                                       + '</p>' +
                       '<p><b> Cleaner </b> : '       + reservation.maid.name                                       + '</p>' +
                       '<p><b> KNF % </b> : '         + reservation.knf_percentage                                  + '</p>' +
                       '<p><b> Night cost </b> : '    + reservation.night_price + ' ' + reservation.currency        + '</p>' +
                       '<p><b> Cleaning cost </b> : ' + reservation.cleaning_cost + ' ' + reservation.currency      + '</p>' +
                       '<p><b> NET Rent </b> : '      + reservation.rent_net + ' ' + reservation.currency           + '</p>' ;
            Modal.inform(title, body);
        };
        var rsrv_id = place.week[day].reservations_id[index]

        if (reservations[rsrv_id])
            display(reservations[rsrv_id], place.address);
        else
            Reservation.get(rsrv_id)
                .then(function (reservation) {
                        reservations[reservation.id] = reservation;
                        display(reservation, place.address);
                    }, function (d) { console.log('no place found')});
    };

}]);
