angular.module('maid')

.controller('MaidPlanning',
    ['MaidReservation',
      function (MaidReservation) {
        var vm = this;

        vm.page_change = function () {
            MaidReservation.all(vm.params)
            .then(function (reservations) {
              vm.reservations = reservations;
            });
        };
        vm.page_change();

    }])