import './activity/controllers.js'

import './user/models.js'
import './user/controllers.js'

import './host/models.js'
import './host/controllers.js'

import './place/models.js'
import './place/controllers.js'
import './place/form-old/main.js'
import './place/form/models.js'
import './place/form/form.controllers.js'

import './reservation/models.js'
import './reservation/controllers.js'

import './notification/models.js'
import './notification/controllers.js'

import './import/models.js'
import './import/controllers.js'

import './payment/models.js'
import './payment/controllers.js'

import './components.js'
import './controllers.js'

import './calendar/controllers.js'

import './cleaning/models.js'
import './cleaning/controllers.missions.js'
import './cleaning/controllers.services.js'

import './dashboard/controllers.js'

import './maid/models.js'
import './maid/services.js'
import './maid/controllers.missions.js'
import './maid/controllers.reports.js'
import './maid/controllers.reservations.js'

import './messaging/models.js'
import './messaging/controllers.js'
