angular.module('user')

.filter('access',
    ['userType', function (userType) {
    return function (account_type, type) {
        return account_type == userType[type].id;
    };
}])

.controller('Account',
    ['Account', '$state', 'Modal', '$stateParams', 'Maid', 'maidStatus', 'userType',
    function Account(Account, $state, Modal, $stateParams, Maid, maidStatus, userType) {
    var vm = this;

    function redirect_after_signin(account) {
        switch (account.type) {
            case userType['ADMIN'].id:
                $state.go('app.reservations');
                break;

            case userType['HOST'].id:
                if (account.completed())
                    $state.go('app.properties');
                else
                    $state.go('app.host');
                break;

            case userType['MAID'].id:
                $state.go('app.missions');
                break;

            default:
                console.log("Undefined Accound type")
                return;
        }
    }

    if ($stateParams.social_token) {
        Account.social_login($stateParams.social_token)
            .then(function () {
                if ($stateParams.created === 'True')
                    $state.go('app.welcome');
                else
                    $state.go('app.host');
            });
    } else {
        Account.current().then(account => redirect_after_signin(account));                  
    }

    vm.password_check = function (form, password1, password2) {
        form.password_confirm.$setValidity('incorrect', password1 === password2);
    };

    vm.signup = function (form, account, password2) {
        vm.error = false;
        vm.error_maid = false;

        vm.password_check(form, account.password, password2);

        if (form.$invalid)
            return;

        Account.signup(account)
            .then(function (r) {
                Account.signin(account.email, account.password)
                       .then(function (account) {
                            $state.go('app.welcome');
                       });
            }, function(r) {
                vm.error = r.data.errors.email;
            });
    };

    vm.signin = function (form, auth) {
        if (form.$invalid)
            return;

        Account.signin(auth.email, auth.password)
            .then((account) => redirect_after_signin(account), function(r) {
            vm.error = true;
        });
    };

    vm.password_reset = function (form, email) {
        if (form.$invalid)
            return;

        Account.password_reset(email)
            .then(function () {
                var title = 'Password reset';
                var content = 'An email has been sent to your email address. ' +
                              'Please follow the instructions to change your password.';

                Modal.inform(title, content);
            }, function (r) {
                vm.error = r.data.errors.email;
            });
    };

    vm.password_change = function (form, password, password2) {
        if (form.$invalid)
            return;

        if (password !== password2) {
            form.password_confirm.$setValidity('incorrect', true);
            return;
        }

        Account.password_change($stateParams.user_id, $stateParams.token, password)
               .then(function(user) {
                    var title = 'Password changed successfully';
                    var content = 'You can now signin with your new password.';

                    Modal.inform(title, content)
                         .then(function () {
                            $state.go('signin');
                         });
                }, function(r) {
                    vm.error = r.data.errors.email;
                });
    };
}])

.controller('AccountConfirm',
    ['$stateParams', 'Account', '$state', 'Modal',
    function AccountConfirm ($stateParams, Account, $state, Modal) {

    if ($stateParams.user_id && $stateParams.token && $stateParams.email)
        Account.confirm($stateParams.user_id, $stateParams.token)
            .then(function () {
                var title = 'Account confirmation';
                var content = 'Your email address is now confirmed.';

                Modal.inform(title, content);

                $state.go('signin');
            });


}]);
