angular.module('reservation')

  .controller('Reservations',
    ['Reservation', 'Place', 'Modal', '$scope', '$timeout', 'status', '$uibModal', '$stateParams',
      function (Reservation, Place, Modal, $scope, $timeout, status, $uibModal, $stateParams) {
        var vm = this;

        vm.params = {
          status: status,
          page: 1,
          q: ''
        };

        vm.params.q = $stateParams.code || '';


        vm.currents = {};

        vm.page_change = function () {
          Reservation.all(vm.params)
            .then(function (reservations) {
              vm.reservations = reservations;
            });
        };
        vm.page_change();

        $scope.$watch('vm.params.q', function (qnew, qold) {
          if (qnew === qold)
            return;

          if (qnew) {
            vm.page_change();
          } else {
            vm.params.page = 1;
            vm.page_change();
          }
        });

        vm.current_init = function (reservation) {
          if (vm.currents[reservation.place.id])
            return;

          vm.currents[reservation.place.id] = reservation.id;
        };

        vm.clean = function (place) {
          if (place.clean)
            return;

          var title = 'Cleaning - place <b>' + place.name + '</b>';
          var body = 'Set the place as cleaned';

          Modal.confirm(title, body).then(function () {
            Place.clean(place.id, true)
              .then(function () {
                place.clean = true;
                vm.success = true;

                $timeout(function () {
                  vm.success = false;
                }, 1000);
              });
          });
        };

        vm.comments = function (comments) {
          Modal.inform('Comments', comments);
        };

        vm.delete = function (reservation) {
          var title = 'Delete reservation';
          var body = 'Confirm the permanent deletion of the reservation';

          Modal.confirm(title, body).then(function () {
            vm.cancel_reservation(reservation)
          });
        };

        vm.metrics = function (reservation) {
          var modal_scope = $scope.$new(false);

          modal_scope.payments = reservation.payments;

          modal_scope.modal = $uibModal.open({
            templateUrl: require('./payments.modal.html'),
            scope: modal_scope,
            size: 'lg',
            windowClass: 'payments-modal'
          });
        };

        vm.cancel_reservation = function (reservation) {
          var modal_scope = $scope.$new(false);

          modal_scope.submitted = false;

          modal_scope.cancel = function (from) {
            if (modal_scope.submitted)
              return;

            modal_scope.submitted = true;

            Reservation.remove(reservation.id, { from: from }).then(function () {
              var total = vm.reservations.total - 1;
              vm.reservations = _.without(vm.reservations, reservation);
              vm.reservations.total = total;
              modal_scope.modal.close()
            });
          };

          modal_scope.modal = $uibModal.open({
            templateUrl: require('./cancel.modal.html'),
            scope: modal_scope,
            backdrop: 'static'
          });
        };
      }])

  .controller('Reservation',
    ['$scope', 'Reservation', 'Place', '$stateParams', '$timeout', '$state', 'Modal', 'Maid',
      function ($scope, Reservation, Place, $stateParams, $timeout, $state, Modal, Maid) {
        var vm = this;

        vm.day_hours = [];
        for (var i = 8; i <= 24; i++)
          vm.day_hours.push(i + ':00');

        Place.get($stateParams.place_id).then(function (place) {
          vm.place = place
        })

        if ($stateParams.reservation_id)
          Reservation.get($stateParams.reservation_id)
            .then(function (reservation) {
              vm.reservation = reservation;

              vm.reservation.date = new Date(vm.reservation.date);

              vm.reservation.from_date = new Date(vm.reservation.from_date);
              vm.from_time = [vm.reservation.from_date.getHours(), '00'].join(':');

              vm.reservation.to_date = new Date(vm.reservation.to_date);
              vm.to_time = [vm.reservation.to_date.getHours(), '00'].join(':');
            });
        else {
          vm.reservation = {
            place_id: $stateParams.place_id,
            date: new Date(),
            currency: 'CHF'
          };

          Place.get($stateParams.place_id).then(function (place) {
            vm.reservation.maid_name = place.maid_name;
            vm.reservation.maid_phone = place.maid_phone;
            vm.reservation.cleaning_cost = place.maid_price;
            vm.reservation.maid = place.maid;
            vm.reservation.maid_sms = place.maid_sms;
            vm.reservation.maid_duration = place.maid_duration;
            vm.reservation.maid_additional_informations = place.maid_additional_informations;
            vm.reservation.maid_access_informations = place.maid_access_informations;
            vm.reservation.cleaning_cost = place.cleaning_cost;
            vm.reservation.cleaning_byhost = place.cleaning_byhost

            vm.reservation.email_in_b7 = place.reservation_email_in_b7;
            vm.reservation.email_out_a1 = place.reservation_email_out_a1;
            vm.reservation.comments = place.reservation_comments;
            vm.reservation.guest_sms = place.reservation_guest_sms;

            if (place.price) {
              vm.reservation.night_price = place.price;
              vm.reservation.currency = place.currency;
            }

            vm.from_time = place.checkin_hour ? place.checkin_hour + ':00' : '15:00';
            vm.to_time = place.checkout_hour ? place.checkout_hour + ':00' : '11:00';

            vm.place = place;
          });

          /*Reservation.last($stateParams.place_id).then(function (reservation) {
            vm.reservation.email_in_b7 = reservation.email_in_b7;
            vm.reservation.email_out_a1 = reservation.email_out_a1;
            vm.reservation.comments = reservation.comments;
          });*/

        }

        $scope.$watchGroup(['vm.reservation.from_date', 'vm.reservation.to_date'],
          function (qnew, qold, scope) {

            if (vm.reservation.from_date && !vm.reservation.to_date) {
              vm.reservation.to_date = new Date(vm.reservation.from_date)
              vm.reservation.to_date.setDate(vm.reservation.to_date.getDate() + 1);
            }

            if (!qnew[0] || !qnew[1])
              return;

            scope.reservation_form.to_date.$setValidity('subsequent', qnew[0] < qnew[1]);

            vm.reservation.night_count = dayjs(vm.reservation.to_date).diff(dayjs(vm.reservation.from_date), 'day') + 1;

            Place.is_available($stateParams.place_id, qnew[0], qnew[1], $stateParams.reservation_id)
              .then(function (res) {
                scope.reservation_form.to_date.$setValidity('unavailable', res != 'unavailable');
                scope.reservation_form.to_date.$setValidity('limit', res != 'limit');
                scope.reservation_form.to_date.$setValidity('canceled', res != 'canceled');
              });
          });

        vm.save = function (form, reservation, from_time, to_time) {
          if (form.$invalid)
            return;

          vm.processing = true;

          var ftime = from_time.split(':');
          reservation.from_date.setHours(ftime[0]);
          reservation.from_date.setMinutes(ftime[1]);

          var ttime = to_time.split(':');
          reservation.to_date.setHours(ttime[0]);
          reservation.to_date.setMinutes(ttime[1]);

          if (reservation.id)
            reservation.put()
              .then(function () {
                vm.processing = false;
                vm.success = "Reservation updated successfully";

                $timeout(function () {
                  $state.go('app.place.reservations', {'place_id': reservation.place_id});
                }, 1000);
              }, (err) => {
                vm.processing = false;
                if (err.status === 422) {
                  Modal.inform('Reservation already exists', 'A reservation with the same code already exists!')
                }
              });
          else {
            var now = new Date();
            var title, content;
            if (reservation.to_date < now) {
              title = 'Confirm the past reservation';
              content = 'As the reservation has ended, the guest won\'t be notified.';
              if (reservation.maid_sms) {
                content += "<br>\nThe maid won't receive an sms."
              }
            } else {
              title = 'Confirm the new reservation';
              content = 'The guest will be notified by email.';
              if (reservation.maid_sms) {
                content += "<br>\nThe maid will also be notified by sms 3 days in advance."
              }
              if (reservation.guest_sms) {
                content += "<br>\nThe guest will also be notified by sms 1 day in advance."
              }
            }

            Modal.confirm(title, content)
              .then(() => {
                Reservation.add(reservation)
                  .then(() => {
                    vm.processing = false;
                    vm.success = "Reservation registered successfully";

                    $timeout(function () {
                      $state.go('app.place.reservations', {'place_id': reservation.place_id});
                    }, 1000);
                  }, (err) => {
                    vm.processing = false;
                    if (err.status === 422) {
                      Modal.inform('Reservation already exists', 'A reservation with the same code already exists!')
                    }
                  });
              }).catch(() => {vm.processing = false;});
          }
        };

        vm.show_reservation = function () {
          const windowSize = {
            width: 800,
            height: 800,
          };
          const windowLocation = {
            left: (window.screen.availLeft + window.screen.availWidth),
            top: (window.screen.availTop + (window.screen.availHeight / 2)) - (windowSize.height / 2)
          };
          window.open(`https://www.airbnb.fr/messaging/qt_for_reservation/${vm.reservation.code}`, '_blank', 'width=' + windowSize.width + ', height=' + windowSize.height + ', left=' + windowLocation.left + ', top=' + windowLocation.top);
        };

        vm.send_guest_confirm_mail = function () {
          Modal.confirm('Send Arrival Informations',
            `Do you really want to send the "Arrival Informations" mail to ${vm.reservation.guest.firstname} ${vm.reservation.guest.lastname} again ?`)
            .then(function () {
              Reservation.send_guest_confirm_mail(vm.reservation.id)
                .then((reservation) => {
                  vm.reservation.arrival_email_date = reservation.arrival_email_date;
                  vm.success = "Arrival reservation email sent";
                  $timeout(function () {
                    vm.success = false;
                  }, 2000);
                });
            });
        };

        vm.send_guest_access_mail = function () {
          Modal.confirm('Send Access Informations',
            `Do you really want to send the "Access Informations" mail to ${vm.reservation.guest.firstname} ${vm.reservation.guest.lastname} ?`)
            .then(function () {
              Reservation.send_guest_access_mail(vm.reservation.id)
                .then((reservation) => {
                  vm.success = "Access email sent";
                  $timeout(function () {
                    vm.success = false;
                  }, 2000);
                });
            });
        }

        vm.maids_search = function (value) {
          return Maid.search(value)
            .then(function (places) {
              return places;
            });
        };
      }])

  .constant('reservationStatus', {
    'PENDING': {'id': 0, 'label': 'waiting guest', 'class': 'default', 'route': 'app.reservations.checkins'},
    'CHECKIN_TODAY': {'id': 1, 'label': 'check-in today', 'class': 'info', 'route': 'app.reservations.checkins'},
    'GUESTIN': {'id': 2, 'label': 'guest in', 'class': 'info', 'route': 'app.reservations.checkouts'},
    'CHECKOUT_TOMORROW': {
      'id': 3,
      'label': 'check-out tomorrow',
      'class': 'info',
      'route': 'app.reservations.checkouts'
    },
    'CHECKOUT_TODAY': {'id': 4, 'label': 'check-out today', 'class': 'success', 'route': 'app.reservations.checkouts'},
    'GUESTOUT': {'id': 5, 'label': 'guest out', 'class': 'success', 'route': 'app.reservations.past'},
    'DRAFT': {'id': 6, 'label': 'draft', 'class': 'default'},
    'CANCELED': {'id': 7, 'label': 'canceled', 'class': 'danger'},
  })

  .directive('reservationLabel',
    ['reservationStatus',
      function (reservationStatus) {
        return {
          restrict: 'A',
          scope: {reservationLabel: '='},
          link: function (scope) {
            scope.status = reservationStatus;
          },
          template: '<span ng-repeat="s in status"' +
          'class="label label-{{ s.class }}"' +
          'ng-show="reservationLabel == s.id">  {{ s.label }} </span>'
        };
      }])


  .controller('Emails',
    ['Email', '$timeout', 'Modal', '$scope', '$uibModal', 'Reservation',
      function (Email, $timeout, Modal, $scope, $uibModal, Reservation) {
        var vm = this;
  
        Email.all()
          .then(function (emails) {
            vm.emails = emails;
          });

        vm.save = function (form, emails) {
          if (form.$invalid)
            return;

          const emails_updated = [];
          for (let i = 0; i < emails.length; i++) {
            const e = emails[i];

            emails_updated.push({
              id: e.id,
              content : e.content
            })
          }

          Email.updates(emails_updated)
            .then(function () {
              vm.success = true;

              $timeout(function () {
                vm.success = false;
              }, 1000);
            });
        };

        var keywords = {
          '%guest_firstname%': 'John',
          '%guest_lastname%': 'Guest',
          '%host_firstname%': 'Simon',
          '%host_lastname%': 'Host',
          '%checkin_date%': '01/01/2016',
          '%checkin_time%': '10:00',
          '%checkin_comment%': 'Remember to give us the special offer coupon at your arrival.',
          '%checkout_date%': '03/01/2016',
          '%checkout_time%': '15:00',
          '%checkout_comment%': 'Time to discover the special gift hidden in the cellar to enjoy your last night.',
          '%place_address%': '32, rue de la boustifaille 1205 Genève',
          '%place_name%': 'Place name',
          '%confirmation_code%': 'CODE123'
        };

        vm.preview = function (content) {
          angular.forEach(keywords, function (value, key) {
            content = content.replace(key, '<b>' + value + '</b>');
          });

          Modal.inform('Email preview', content);
        };

        vm.attachment = function (email) {
          var modal_scope = $scope.$new(false);

          modal_scope.email = email;

          modal_scope.modal = $uibModal.open({
            templateUrl: require('./email.attachment.html'),
            scope: modal_scope,
            backdrop: 'static'
          });

          var upload = function (file) {
            var datas = new FormData();
            datas.append('file', file);

            Email.media_add(datas)
              .then(function (response) {
                if (response.result === 'ok') {
                  if (!modal_scope.email.attachments)
                    modal_scope.email.attachments = [];

                  var attachment = {
                    url: response.url,
                    name: response.name,
                    filename: response.filename
                  }

                  email.attachments.push(attachment);
                } else {
                  var title = 'Incorrect file : ' + response.name;
                  var body = 'Your file could not be uploaded. <br>' +
                    'Only files with a size under 3 Mo ' +
                    'and with the correct format (png, jpg, gif, pdf) are authorized.';

                  Modal.inform(title, body);
                }

              }, function () {
                console.log('serveur error');
              });
          };

          modal_scope.uploads = function (files) {
            angular.forEach(files,
              function (file) {
                upload(file);
              });
          };

          modal_scope.remove = function (index) {
            var title = 'Remove this file ?';
            var body = 'Your file will be removed';

            Modal.confirm(title, body)
              .then(function () {
                modal_scope.email.attachments.splice(index, 1);
              });
          };

          modal_scope.save = function () {
            Email.update(modal_scope.email)
              .then(function () {
                modal_scope.modal.close();
              });
          };
        };
      }])

  .filter('fileName',
    function () {
      return function (url) {
        return url.split('/')[url.length];
      }
    }
  )


  .controller('Payments',
    ['$scope', 'Payment', 'Modal', '$uibModal', '$timeout',
      function ($scope, Payment, Modal, $uibModal, $timeout) {
        var vm = this;

        vm.params = {
          page: 1,
          per_page: 20,
        };

        vm.page_change = function () {
          Payment.all(vm.params)
            .then(function (payments) {
              vm.payments = payments;
            });
        };
        vm.page_change();

        $scope.$watchGroup(['vm.params.q', 'vm.params.date'],
          function (qnew, qold, scope) {

            if (qnew[0] || qnew[1])
              vm.page_change();
            else {
              vm.params.page = 1;
              vm.page_change();
            }
          });

        vm.done = function (payment) {
          if (payment.done)
            return;

          var title = 'Payment - reservation <b>' + payment.reservation.code + '</b>';
          var body = 'Notify the payment for the host as transferred';

          Modal.confirm(title, body)
            .then(function () {
              Payment.done(payment.id)
                .then(function (date) {
                  payment.done = date;

                  vm.success = true;

                  $timeout(function () {
                    vm.success = false;
                  }, 1000);
                });
            });
        };

        vm.bank_account = function (host) {
          var modal_scope = $scope.$new(false);

          modal_scope.host = host;

          modal_scope.modal = $uibModal.open({
            templateUrl: require('../host/inform.html'),
            scope: modal_scope,
            backdrop: 'static'
          });
        };
      }])

  .directive('paymentLabel', function () {
    return {
      restrict: 'A',
      scope: {paymentLabel: '='},
      template: '<span class="label label-info" ng-if="paymentLabel" title="Sent on {{ paymentLabel | date:\'shortDate\' }}"> {{ paymentLabel | date:\'shortDate\' }} </span>' +
      '<span class="label label-default" ng-if="!paymentLabel"> pending </span>'
    };
  });
