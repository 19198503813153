import 'angular'
import 'angular-sanitize'
import 'angular-chart.js'

import './static/js/restangular'
import './static/js/dayjs-custom-parse-format.min.js'
import './static/js/underscore.js'
import './static/js/angular-ui-bootstrap.js'
import './static/js/angular-ui-rooter.js'
import './static/js/angular-messages.js'
import './static/js/switch/bsSwitch.js'
import './static/js/angular-locale_en-ch.js'
import './static/js/ng-file-upload.min.js'
import './static/js/ng-map.min.js'
import './static/js/Chart.min.js'
import './static/js/frontend-image-compress.min.js'
import './static/js/ng-sortable.js'

import './app/index.js'
import './static/less/main.less'
import './app/modal.inform.html'


var app = angular.module('app',
    ['restangular',
        'ui.bootstrap',
        'ui.router',
        'ngSanitize',
        'ngMessages',
        'bootstrap-switch',
        'ngFileUpload',
        'ng-sortable',
        'ngMap',
        'chart.js',
        'editingPlace',
        'user',
        'host',
        'place',
        'reservation',
        'notification',
        'import',
        'payment',
        'knf-components',
        'core-controller',
        'dashboard',
        'calendar',
        'cleaning',
        'maid',
        'activity',
        'messaging',

    ])

    .constant('configs',
        {
            'api': process.env.API_URL,
            'contact_email': 'info@keysnfly.com'
        }
    )

    .directive("deferredCloak", function () {
        return {
            restrict: 'A',
            link: function (scope, element, attrs) {
                attrs.$set("deferredCloak", undefined);
                element.removeClass("deferred-cloak");
            }
        };
    })

    .config(
        ['$stateProvider', '$urlRouterProvider', 'RestangularProvider', 'configs', '$httpProvider',
            function ($stateProvider, $urlRouterProvider, RestangularProvider, configs, $httpProvider) {

                $urlRouterProvider.otherwise('/signin');

                $stateProvider

                    .state('signup', {
                        url: '/signup',
                        controller: 'Account',
                        templateUrl: require('./app/user/signup.html'),
                        controllerAs: 'vm'
                    })

                    .state('confirm', {
                        url: '/confirm/:user_id/:token/:email',
                        templateUrl: require('./app/user/signin.html'),
                        controller: 'AccountConfirm',
                    })

                    .state('signin', {
                        url: '/signin',
                        controller: 'Account',
                        templateUrl: require('./app/user/signin.html'),
                        controllerAs: 'vm'
                    })

                    .state('social-login', {
                        url: '/social-login/:social_token/:created/',
                        controller: 'Account',
                        templateUrl: require('./app/user/signin.html'),
                        controllerAs: 'vm'
                    })

                    .state('password-reset', {
                        url: '/password-reset',
                        controller: 'Account',
                        templateUrl: require('./app/user/password.reset.html'),
                        controllerAs: 'vm'
                    })

                    .state('password-change', {
                        url: '/password-change/:user_id/:token/:email',
                        templateUrl: require('./app/user/password.change.html'),
                        controller: 'Account',
                        controllerAs: 'vm'
                    })

                    .state('app', {
                        abstract: 'true',
                        url: '/admin',
                        views: {
                            'menu': {
                                templateUrl: require('./app/menu.html'),
                                controller: 'Main',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            account: function (Account) {
                                return Account.current();
                            },
                        }
                    })

                    /* customer */
                    .state('app.host', {
                        url: '/profile',
                        views: {
                            'content@': {
                                templateUrl: require('./app/host/form.html'),
                                controller: 'Profile',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            account: function (Account) {
                                return Account.current();
                            },
                        }
                    })

                    /* admin */
                    .state('app.dashboard', {
                        url: '/dashboard',
                        views: {
                            'content@': {
                                templateUrl: require('./app/dashboard/dashboard.html'),
                                controller: 'Dashboard',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.calendar', {
                        url: '/calendar?places'
                            + '&with_overlapping'
                            + '&with_vacant'
                            + '&with_checkins'
                            + '&with_checkouts'
                            + '&selection'
                            + '&from',
                        views: {
                            'content@': {
                                templateUrl: require('./app/calendar/calendar.html'),
                                controller: 'Calendar',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.hosts', {
                        url: '/hosts',
                        views: {
                            'content@': {
                                templateUrl: require('./app/host/hosts.html'),
                                controller: 'Hosts',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.hosts.properties', {
                        url: '/:host_id/places',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/properties.html'),
                                controller: 'Properties',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    /* customer */
                    .state('app.welcome', {
                        url: '/welcome',
                        views: {
                            'content@': {
                                templateUrl: require('./app/host/welcome.html'),
                            }
                        }
                    })
                    .state('app.properties', {
                        url: '/your-places',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/properties.html'),
                                controller: 'HostPlaces',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            account: function (Account) {
                                return Account.current();
                            },
                        }
                    })
                    // .state('app.pform-summary', {
                    //     url: '/:place_id/summary',
                    //     views: {
                    //         'content@': {
                    //             templateUrl: require('./app/place/form/summary.html'),
                    //             controller: 'PlaceFormMain',
                    //             controllerAs: 'vm',
                    //         }
                    //     }
                    // })
                    .state('app.pform', {
                        abstract: 'true',
                        url: '/{place_id:int}/{listing_id}/form',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/form/edit.html'),
                                controller: 'EditingPlace',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.pform.listing_details', {
                        url: '/listing-details',
                        templateUrl: require('./app/place/form/details.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.pricing_availability', {
                        url: '/pricing-availability',
                        templateUrl: require('./app/place/form/pricing_availability.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.policies_rules', {
                        url: '/policies-rules',
                        templateUrl: require('./app/place/form/policies_rules.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.guest_infos', {
                        url: '/guest-infos',
                        templateUrl: require('./app/place/form/guest_infos.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.others_informations', {
                        url: '/others-informations',
                        templateUrl: require('./app/place/form/others_informations.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.admin', {
                        url: '/admin',
                        templateUrl: require('./app/place/form/admin.html'),
                        controllerAs: 'vm',
                    })
                    .state('app.pform.advanced_parameters', {
                        url: '/advanced-parameters',
                        templateUrl: require('./app/place/form/advanced_parameters.html'),
                        controllerAs: 'vm',
                    })
                    // .state('app.pform', {
                    //     url: '/{place_id:int}/form',
                    //     params: {place_id: {value: null, squash: true}},
                    //     views: {
                    //         'content@': {
                    //             templateUrl: require('./app/place/form/main.html'),
                    //             controller: 'PlaceFormMain',
                    //             controllerAs: 'vm',
                    //         }
                    //     }
                    // })
                    // .state('app.pform.address', {
                    //     url: '/address?host_id',
                    //     params: {host_id: null},
                    //     templateUrl: require('./app/place/form/address.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.space', {
                    //     url: '/space',
                    //     templateUrl: require('./app/place/form/space.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.amenities', {
                    //     url: '/amenities/:place_id',
                    //     templateUrl: require('./app/place/form/amenities.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.photos', {
                    //     url: '/photos',
                    //     templateUrl: require('./app/place/form/photos.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.description', {
                    //     url: '/description',
                    //     templateUrl: require('./app/place/form/description.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.rules', {
                    //     url: '/rules',
                    //     templateUrl: require('./app/place/form/rules.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.management', {
                    //     url: '/management',
                    //     templateUrl: require('./app/place/form/management.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.timings', {
                    //     url: '/timings',
                    //     templateUrl: require('./app/place/form/timings.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.duration', {
                    //     url: '/duration',
                    //     templateUrl: require('./app/place/form/duration.html'),
                    //     controllerAs: 'vm',
                    // })
                    .state('app.pform.parameters', {
                        url: '/parameters',
                        templateUrl: './app/place/form/parameters.html',
                        controllerAs: 'vm',
                    })
                    // .state('app.pform.pricing', {
                    //     url: '/pricing',
                    //     templateUrl: require('./app/place/form/pricing.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.discounts', {
                    //     url: '/discounts',
                    //     templateUrl: require('./app/place/form/discounts.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.cleanings', {
                    //     url: '/cleanings',
                    //     templateUrl: require('./app/place/form/cleanings.html'),
                    //     controllerAs: 'vm',
                    // })
                    // .state('app.pform.interlocutor', {
                    //     url: '/interlocutor',
                    //     templateUrl: require('./app/place/form/interlocutor.html'),
                    //     controllerAs: 'vm',
                    // })

                    /* admin */
                    .state('app.places', {
                        url: '/places',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/places.html'),
                                controller: 'Places',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.place', {
                        url: '/places/:place_id',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/place.html'),
                                controller: 'Place',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    .state('app.activity', {
                        url: '/activity',
                        views: {
                            'content@': {
                                templateUrl: require('./app/activity/activity.html'),
                                controller: 'Activity',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.place.activity', {
                        url: '/places/:place_id/activity',
                        views: {
                            'content@': {
                                templateUrl: require('./app/activity/activity.html'),
                                controller: 'Activity',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    /* host */
                    .state('app.place.booking', {
                        url: '/booking',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/booking.html'),
                                controller: 'PlaceReservations',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    .state('app.place.exceptionnal_entries', {
                        url: '/exceptionnal_entries',
                        templateUrl: require('./app/place/exceptionnal_entries.html'),
                    })

                    .state('app.availabilities', {
                        url: '/places/:place_id/availabilities',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/availabilities.html'),
                                controller: 'Place',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    .state('app.statistics', {
                        url: '/places/:place_id/statistics',
                        views: {
                            'content@': {
                                templateUrl: require('./app/dashboard/dashboard.html'),
                                controller: 'Dashboard',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    /* admin */
                    .state('app.place.reservations', {
                        url: '/reservations',
                        views: {
                            'content@': {
                                templateUrl: require('./app/place/reservations.html'),
                                controller: 'PlaceReservations',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    /* admin */
                    .state('app.place.reservations.add', {
                        url: '/add',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/form.html'),
                                controller: 'Reservation',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    /* admin */
                    .state('app.place.reservations.reservation', {
                        url: '/:reservation_id',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/form.html'),
                                controller: 'Reservation',
                                controllerAs: 'vm'
                            }
                        }
                    })

                    /* admin */
                    .state('app.reservations', {
                        url: '/reservations',
                        redirectTo: 'app.reservations.all',
                    })
                    .state('app.reservations.all', {
                        url: '/checkins?code',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/list.all.html'),
                                controller: 'Reservations',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            status: function (reservationStatus) {
                                return false
                            },
                        }
                    })
                    .state('app.reservations.checkins', {
                        url: '/checkins?code',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/list.checkins.html'),
                                controller: 'Reservations',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            status: function (reservationStatus) {
                                return reservationStatus.PENDING.id;
                            },
                        }
                    })
                    .state('app.reservations.checkouts', {
                        url: '/checkouts?code',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/list.checkouts.html'),
                                controller: 'Reservations',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            status: function (reservationStatus) {
                                return reservationStatus.GUESTIN.id;
                            },
                        }
                    })
                    .state('app.reservations.past', {
                        url: '/past?code',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/list.past.html'),
                                controller: 'Reservations',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            status: function (reservationStatus) {
                                return reservationStatus.GUESTOUT.id;
                            },
                        }
                    })
                    .state('app.reservations.canceled', {
                        url: '/canceled?code',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/list.past.html'),
                                controller: 'Reservations',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            status: function (reservationStatus) {
                                return reservationStatus.CANCELED.id;
                            },
                        }
                    })
                    /* imports */
                    .state('app.imports', {
                        url: '/imports',
                        redirectTo: 'app.imports.new',
                    })
                    .state('app.imports.new', {
                        url: '/new',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/list.new.html'),
                                controller: 'Imports',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            type: function (reservationImportType) {
                                return reservationImportType.NEW.id;
                            },
                        }
                    })
                    .state('app.imports.updated', {
                        url: '/updated',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/list.updated.html'),
                                controller: 'Imports',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            type: function (reservationImportType) {
                                return reservationImportType.UPDATED.id;
                            },
                        }
                    })
                    .state('app.imports.canceled', {
                        url: '/canceled',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/list.canceled.html'),
                                controller: 'Imports',
                                controllerAs: 'vm',
                            }
                        },
                        resolve: {
                            type: function (reservationImportType) {
                                return reservationImportType.CANCELED.id;
                            },
                        }
                    })
                    .state('app.imports.new.detail', {
                        url: '/:import_id',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/import.new.html'),
                                controller: 'ImportNew',
                                controllerAs: 'vm',
                            }
                        },
                    })
                    .state('app.imports.updated.detail', {
                        url: '/:import_id',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/import.updated.html'),
                                controller: 'ImportUpdated',
                                controllerAs: 'vm',
                            }
                        },
                    })
                    .state('app.imports.canceled.detail', {
                        url: '/:import_id',
                        views: {
                            'content@': {
                                templateUrl: require('./app/import/import.canceled.html'),
                                controller: 'ImportCanceled',
                                controllerAs: 'vm',
                            }
                        },
                    })
                    .state('app.payments', {
                        url: '/payments?date',
                        views: {
                            'content@': {
                                templateUrl: require('./app/payment/payments.html'),
                                controller: 'Payments',
                                controllerAs: 'vm'
                            }
                        }
                    })
                    .state('app.payments.report', {
                        url: '/reports/:host/:date',
                        views: {
                            'content@': {
                                templateUrl: require('./app/payment/report.html'),
                                controller: 'PaymentReport',
                                controllerAs: 'vm',
                            }
                        }
                    })

                    .state('app.notifications', {
                        url: '/notifications',
                        views: {
                            'content@': {
                                templateUrl: require('./app/notification/notifications.html'),
                                controller: 'Notifications',
                                controllerAs: 'vm'
                            }
                        },
                        resolve: {
                            account: function (Account) {
                                return Account.current();
                            },
                        }
                    })

                    /* cleaning */
                    .state('app.cleaning', {
                        url: '/cleaning',
                        redirectTo: 'app.cleaning.missions',
                    })
                    .state('app.cleaning.missions', {
                        url: '/missions',
                        views: {
                            'content@': {
                                templateUrl: function(){
                                    if(window.innerWidth < 768){
                                        return require('./app/cleaning/missions.mobile.html')
                                    }
                                    return require('./app/cleaning/missions.desktop.html')
                                },
                                controller: 'Missions',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.cleaning.reports', {
                        url: '/reports',
                        views: {
                            'content@': {
                                templateUrl: function(){
                                    if(window.innerWidth < 768){
                                        return require('./app/cleaning/reports.mobile.html')
                                    }
                                    return require('./app/cleaning/reports.desktop.html')
                                },
                                controller: 'Reports',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.cleaning.services', {
                        url: '/services',
                        views: {
                            'content@': {
                                templateUrl: require('./app/cleaning/services.html'),
                                controller: 'Services',
                                controllerAs: 'vm',
                            }
                        }
                    })

                    /* Maid */
                    .state('app.missions', {
                        url: '/missions',
                        views: {
                            'content@': {
                                templateUrl: function(){
                                    if(window.innerWidth < 768){
                                        return require('./app/maid/missions.mobile.html')
                                    }
                                    return require('./app/maid/missions.desktop.html')
                                },
                                controller: 'MaidMissions',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.reports', {
                        url: '/reports',
                        views: {
                            'content@': {
                                templateUrl: function(){
                                    if(window.innerWidth < 768){
                                        return require('./app/maid/reports.mobile.html')
                                    }
                                    return require('./app/maid/reports.desktop.html')
                                },
                                controller: 'MaidReports',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.planning', {
                        url: '/planning',
                        views: {
                            'content@': {
                                templateUrl: function(){
                                    if(window.innerWidth < 768){
                                        return require('./app/maid/planning.mobile.html')
                                    }
                                    return require('./app/maid/planning.desktop.html')
                                },
                                controller: 'MaidPlanning',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    /* cleaning */
                    .state('app.messaging', {
                        url: '/messaging',
                        redirectTo: 'app.messaging.messages',
                    })
                    .state('app.messaging.messages', {
                        url: '/messaging',
                        views: {
                            'content@': {
                                templateUrl: require('./app/messaging/messaging.html'),
                                controller: 'Messaging',
                                controllerAs: 'vm',
                            }
                        }
                    })
                    .state('app.messaging.emails', {
                        url: '/emails',
                        views: {
                            'content@': {
                                templateUrl: require('./app/reservation/emails.html'),
                                controller: 'Emails',
                                controllerAs: 'vm'
                            }
                        }
                    });

                RestangularProvider.setBaseUrl(configs.api);

                RestangularProvider.setRequestInterceptor(function(elem, operation) {
                    if (operation === "remove") {
                       return undefined;
                    }
                    return elem;
                });

                RestangularProvider.addResponseInterceptor(function (data, operation, what, url, response, deferred) {

                    if (typeof data === 'string'){
                        return {status: data}
                    }

                    if (operation !== "getList" || data.items === undefined)
                        return data;

                    var extractedData = data.items;
                    extractedData.total = data.total;
                    extractedData.pages = data.pages;
                    return extractedData;
                });

                var excludes = [
                    '/api/v1/notifications/todo',
                    '/api/v1/reservations/unverified',
                    '/api/v1/reports/pending'
                ]

                var interceptor = ['$q', '$rootScope', function ($q, $rootScope) {
                    var requests_count = 0;

                    return {
                        request: function (config) {
                            if (excludes.indexOf(config.url) > -1)
                                return config;

                            requests_count += 1;
                            $rootScope.progress = true;
                            return config;
                        },
                        requestError: function (error) {
                            if (excludes.indexOf(error.url) > -1)
                                return $q.reject(error);

                            requests_count -= 1;
                            $rootScope.progress = (requests_count !== 0);
                            return $q.reject(error);
                        },
                        response: function (response) {
                            if (excludes.indexOf(response.config.url) > -1)
                                return response;

                            requests_count -= 1;
                            $rootScope.progress = (requests_count !== 0);
                            return response;
                        },
                        responseError: function (error) {
                            if (excludes.indexOf(error.url) > -1)
                                return $q.reject(error);

                            requests_count -= 1;
                            $rootScope.progress = (requests_count !== 0);
                            return $q.reject(error);
                        }
                    };
                }];

                $httpProvider.interceptors.push(interceptor);
            }]);

app.run(
    ['Restangular', 'Account', '$rootScope', '$state', 'Modal', 'configs',
        function (Restangular, Account, $rootScope, $state, Modal, configs) {


            Restangular.setDefaultHeaders({
                'Authorization': function () {
                    /* Important : only set bearer token if defined
                        Or the API will consider to check the token with Null value.
                    */
                    if (localStorage.getItem('token') != null){
                        return 'Bearer ' + localStorage.getItem('token')
                    }
                    return ''
                }
            });


            Restangular.setErrorInterceptor(function (response, deferred, responseHandler) {

                if (response.status === 500 || response.status === 404) {

                    let title = 'An error occurred';
                    let body = 'Please help us improve the service by contacting us at <b><a href="mailto:' + configs.contact_email + '"> ' + configs.contact_email + '</a></b>';

                    console.log(title, body);
                }

                let status = ["signin", "signup", "password-reset", "password-change"]

                if (response.status === 401 && !status.includes($state.current.name)) {

                    $state.go('signin');
                    if (Account.current_exists())
                        Account.current()
                            .then(function (account) {
                                account.signout();
                            });


                    let title = 'You have been disconnected';
                    let body = 'You have been disconnected due to inactivity. Please reconnect.';

                    Modal.inform(title, body);
                }

                return deferred.promise;
            });

            $rootScope.$on('$stateChangeStart', function (evt, to, params) {
                if (to.redirectTo) {
                    evt.preventDefault();
                    $state.go(to.redirectTo, params)
                }
            });

        }])
